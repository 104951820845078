import { useState, useEffect } from "react";

import Cookies from 'universal-cookie';

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { GetDataPage } from "interface/Data";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import { SvgArrow_Return } from "components/SvgFile";

import { cookie_email, cookie_passw } from "dataFixed";

import { Access } from "services/Login";

import Menu from "components/Menu";
import Footer from "components/Footer";

import Page_Rolo from "components/Page/Rolo";
import Page_Home from "components/Page/Home";
import Page_Login from "components/Page/Login";
import Page_Course from "components/Page/Course";
import Page_Recover from "components/Page/Recover";
import Page_HowWorks from "components/Page/HowWorks";
import Page_Mentoring from "components/Page/Mentoring";
import Page_Dashboard from "components/Page/Dashboard";

import Model_Payment from "components/Model/Payment";
import Model_Details from "components/Model/Details";

import PopUP_ReturnResponse from "components/PopUp/ReturnResponse";

export default function App() {

    const cookies  = new Cookies();
    
    const [ showData, setShowData ] = useState(GetDataPage('settings'));

    const [ loading, setLoading ]         = useState(false); 
    const [ currentPage, setCurrentPage ] = useState(GetListPag('page'));
    const [ course, setCourse ]           = useState(GetDataPage('course'));

    const [ url, setUrl ]       = useState(window.location.href.split("/"));
    const [ infUrl, setInfUrl ] = useState(0);

    const [ statusLogin, setStatusLogin ] = useState(false);

    function CallbackSuccess(value){
        setStatusLogin(value);
    }
    
    function NamePageUrl(){
        if(url){
            if(course.find(item => item.title_url == url[3])){
                SetListPag('page', url[3]);
            }else {
                let segund = url[3].split('=');
                if(GetDataPage('recover').find(item => item == segund[1])){
                    SetListPag('page', url[3]);

                }else {
                    switch (url[3]) {
                        case "index": case "howworks": case "mentoring": case "course": case "rolo": case "login": case "enrollment": case "payment": case "logout": case "recover": case "inscription": case "dash_classe_detalis": case "details":
                                SetListPag('page', url[3]);
                            break;
                        default:
                                SetListPag('page', 'index');
                            break;
                    }
                }
            }
        }else{
            SetListPag('page', 'index');
        }
    }

    function CkickPage(value){      
        switch (value) {
            case "index":
                    SetListPag('page', value);   
                break;
        
            default: 
                    SetListPag('remuve', 'All');
                    SetListPag('page', 'index');   
                break;
        }
    }

    function ReturnIndex(){
        document.getElementById('home').scrollTo({ top: 0, behavior: 'smooth' });
    }

    useEffect(()=>{
        RegisterListPag('page', setCurrentPage);
        NamePageUrl();

        if(cookies.get(cookie_passw) || cookies.get(cookie_email)){
            let return_pass  = cookies.get(cookie_passw);
            let return_email = cookies.get(cookie_email);
            Access('return', return_email, return_pass, ()=>{ CallbackSuccess(true) }, ()=>{ });
        }
    }, []);

    return (
        <Router>
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <div className="dot-spinner">
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                </div>
            </div>

            {
                showData.whatsapp == "" ? null :
                <div className="whatsapp">
                    <a href={ "https://wa.me/+55" + showData.whatsapp } target="_blank">
                        <img alt="Whatsapp" src="./assets/icons_svg/whatsapp.svg" className="icon_whatsapp" />
                    </a>
                </div>
            }

            <Menu ReturnIndex={ ReturnIndex } CkickPage={ CkickPage } />
            <div className="height_space_menu" />

            <Routes>                
                <Route exact path="/" element={ <Page_Home ReturnIndex={ ReturnIndex } CkickPage={ CkickPage } setLoading={ setLoading } /> } />
                <Route exact path="*" element={ <Page_Home ReturnIndex={ ReturnIndex } CkickPage={ CkickPage } setLoading={ setLoading } /> } />
                <Route exact path="/howworks" element={ <Page_HowWorks ReturnIndex={ ReturnIndex } CkickPage={ CkickPage } setLoading={ setLoading } /> } />
                <Route exact path="/mentoring" element={ <Page_Mentoring ReturnIndex={ ReturnIndex } CkickPage={ CkickPage } setLoading={ setLoading } /> } />
                <Route exact path="/rolo" element={ <Page_Rolo ReturnIndex={ ReturnIndex } CkickPage={ CkickPage } setLoading={ setLoading } /> } />
                <Route exact path="/course" element={ <Page_Course ReturnIndex={ ReturnIndex } CkickPage={ CkickPage } setLoading={ setLoading } /> } />

                <Route exact path="/login" element={ statusLogin ? <Page_Dashboard ReturnIndex={ ReturnIndex } CkickPage={ CkickPage } setLoading={ setLoading } CallbackSuccess={ CallbackSuccess } /> : <Page_Login ReturnIndex={ ReturnIndex } CkickPage={ CkickPage } setLoading={ setLoading } CallbackSuccess={ CallbackSuccess } /> 
                } />

                <Route exact path="/payment" element={ <Model_Payment ReturnIndex={ ReturnIndex } CkickPage={ CkickPage } setLoading={ setLoading } RegisterAccess={ CallbackSuccess }  /> } />

                {
                    course.map((elem, index)=>{
                        return <Route exact path={ "/" + elem.title_url } element={ <Model_Details ReturnIndex={ ReturnIndex } CkickPage={ CkickPage } setLoading={ setLoading } origin={ elem.title_url } /> } key={ index } />
                    })
                }                

                {
                    GetDataPage('recover').map((elem, index)=>{
                        return(
                            <Route exact path={ "/recover=" + elem } element={ <Page_Recover ReturnIndex={ ReturnIndex } CkickPage={ CkickPage } code={ elem } setLoading={ setLoading } /> } key={ index } />
                        )
                    })
                }
            </Routes>

            <Footer ReturnIndex={ ReturnIndex } CkickPage={ CkickPage } />
            
            <PopUP_ReturnResponse />
        </Router>
    );
}