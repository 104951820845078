import { useState, useEffect } from "react";

import './Details.css';

import Vimeo from '@u-wave/react-vimeo';

import { Link } from "react-router-dom";

import { SetListPag } from "interface/Page";
import { GetDataPage } from "interface/Data";

export default function Model_Details(props){

    const [ course, setCourse ]           = useState(InitialData());
    const [ showModules, setShowModules ] = useState(course.list_module);

    function InitialData(){
        const newData = GetDataPage('course').find(item => item.title_url == props.origin);
        return newData;
    }

    return(
        <div className="Model_Details">
            <div className="container">
                <div className="div_title_course">
                    <div className="div_img">
                        <img alt="img" src={ course.file } className="img_course" />
                    </div>
                    <div className="div_title">                            
                        { course.title }
                    </div>
                </div>

                <div className="div_data_course">
                    <div className="inf_course">
                        <div className="div_video">
                            <Vimeo className="div_show_video" width="100%" height="100%" video={ course.video } />
                        </div>
                        <div className="div_text">
                            <div className="text" dangerouslySetInnerHTML={ { __html: course.hot_page ? course.hot_page.replaceAll('&#34;', '"') : "" } } />
                        </div>
                    </div>
                    <div className="list_module">
                        {
                            showModules.map((elem, index)=>{
                                return(
                                    <div className="div_module" key={ index }>
                                        <div className="show_modules">
                                            {/* 
                                            <div className="icon_arrow">
                                                {
                                                    elem.status == false ? 
                                                    <SvgSetaDown className="icons arrow_rotate" color="#ffffff" /> :
                                                    <SvgSetaDown className="icons" color="#ffffff" />
                                                }
                                            </div> 
                                            */}
                                            <div className="">
                                                <div className="name_modules">{ elem.name }</div>
                                            </div>
                                            {/* 
                                            <div className="qtd_classes">
                                                {
                                                    elem.list_classe.length
                                                }
                                            </div> 
                                            */}
                                        </div>
                                        <div className="div_list_classes" id={ "accordion_" + index }>
                                            {
                                                elem.list_classe.map((elem_1, index_1)=>{
                                                    return(
                                                        <div className="list_classes" key={ index_1 }>
                                                            { elem_1.title }
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <div className="div_module">
                            <Link to="/payment" onClick={ ()=>{ SetListPag('page', 'payment'); props.ReturnIndex(); } }>
                                <div className="show_modules div_btn">
                                    Visualizar planos
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}